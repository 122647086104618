var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: _vm.pageTitle } },
    [
      _c("vx-card", [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center mt-3 mb-4" },
          [
            _c("vs-avatar", {
              staticClass: "mr-4 mb-4 reward-avatar",
              attrs: {
                src: _vm.form.imageUrl,
                icon: "cloud_upload",
                size: "70px",
                disabled: _vm.readOnly,
              },
              on: {
                click: function ($event) {
                  return _vm.$refs.rewardImageInput.click()
                },
              },
            }),
            _c(
              "div",
              [
                _c("input", {
                  ref: "rewardImageInput",
                  attrs: {
                    type: "file",
                    hidden: "",
                    id: "rewardImageInput",
                    accept: "image/*",
                  },
                  on: { change: _vm.handleFileSelection },
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.rewardImageInput.click()
                      },
                    },
                  },
                  [_vm._v("Select Image")]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: {
                      type: "border",
                      color: "danger",
                      disabled: _vm.readOnly,
                    },
                    on: { click: _vm.removeRewardImage },
                  },
                  [_vm._v("Remove")]
                ),
                _c("p", { staticClass: "text-sm mt-2" }, [
                  _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Name*")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name", disabled: _vm.readOnly },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Description*")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:200",
                    expression: "'required|max:200'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Description", disabled: _vm.readOnly },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Description"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-2" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Terms")]),
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:1000",
                    expression: "'max:1000'",
                  },
                ],
                staticClass: "w-full mb-4",
                attrs: { name: "Terms", rows: "2", disabled: _vm.readOnly },
                model: {
                  value: _vm.form.terms,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "terms", $$v)
                  },
                  expression: "form.terms",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Terms"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("label", {}, [_vm._v("Modifier Type")]),
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Modifier Type",
                  options: _vm.modifierOptions,
                  reduce: (x) => x.code,
                  clearable: false,
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.isPercentage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isPercentage", $$v)
                  },
                  expression: "form.isPercentage",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Modifier Type"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("label", {}, [_vm._v("Modifier Value")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal|greaterThanZero",
                    expression: "'required|decimal|greaterThanZero'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Modifier Value",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", _vm._n($$v))
                  },
                  expression: "form.amount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Modifier Value"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row flex flex-wrap justify-start mb-8" }, [
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("label", {}, [_vm._v("Maximum Reward Amount")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "",
                    expression: "''",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Maximum Reward Amount",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.maxAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "maxAmount", _vm._n($$v))
                  },
                  expression: "form.maxAmount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Maximum Reward Amount"))),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "mt-8" }, [
            _vm._v(_vm._s(_vm.appDefaultCurrency)),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "w-full",
                  attrs: { disabled: _vm.readOnly },
                  model: {
                    value: _vm.form.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isActive", $$v)
                    },
                    expression: "form.isActive",
                  },
                },
                [_vm._v("Active")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              { attrs: { color: "danger" }, on: { click: _vm.onCancel } },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-4",
                attrs: { disabled: _vm.readOnly },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }